<template>
  <main>
    <BlueprintBlackLogo class="logo" />
    <section class="main-section">
      <h2 class="header">All set!</h2>
      <AvatarCircle :size="550" :avatar="store.state.avatar" should-show-third />
    </section>
  </main>
</template>

<script setup>
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import BlueprintBlackLogo from '@/components/ui/BlueprintBlackLogo'
import AvatarCircle from '@/components/AvatarCircle'

const store = useStore()
const router = useRouter()

setTimeout(() => {
  router.push('/')
}, 2000)
</script>

<style scoped>
.logo {
  margin-top: 40px;
  margin-left: 100px; /* fix it later? */
}

.main-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  font-family: "Helvetica Neue Lt Std Bold", Arial, sans-serif;
  font-size: 64px;
  color: black;
}
</style>
